import { useStore } from "effector-react";
import styled from "styled-components";

import { Button } from "shared/ui/Button";
import { Loader } from "shared/ui/Loader";
import { Modal } from "shared/ui/Modal";
import { OutlinedButton } from "shared/ui/OutlinedButton";
import { Typography } from "shared/ui/Typography";

import {
  $customerMarkedForDeactivating,
  $isDeactivateCustomerModalVisible,
  $isDeactivation,
  $isSuccessMessageVisible,
  cancelCustomerDeactivation,
  deactivateCustomer,
} from "./model";

const ModalButton = styled(Button)`
position: relative;
  margin-top: 18px;
  margin-bottom: 16px;
`;

const NextButton = styled(Button)`
  margin-top: 18px;
`;

export const DeactivationCustomerModal: React.FC = () => {
  const isVisible = useStore($isDeactivateCustomerModalVisible);
  const customer = useStore($customerMarkedForDeactivating);
  const isSuccess = useStore($isSuccessMessageVisible);
  const isDeactivation = useStore($isDeactivation);
  const handleClose = () => cancelCustomerDeactivation();
  const handleDeactivateUser = () => {
    console.log("customer", customer);
    
    if (customer) deactivateCustomer(customer);
  };

  return (
    <Modal isOpen={isVisible} onClose={handleClose}>
      {isSuccess ? (
        <>
          <Typography variant="bold20">
            User {customer?.firstName} {customer?.lastName} has successfully been
            deactivated
          </Typography>

          <NextButton onClick={handleClose}>Continue</NextButton>
        </>
      ) : (
        <>
          <Typography variant="bold20">
            Deactivate user {customer?.firstName} {customer?.lastName}?
          </Typography>
          <ModalButton disabled={isDeactivation} onClick={handleDeactivateUser}>{isDeactivation && <Loader width="100%" height={"100%"} />} Yes</ModalButton>
          <OutlinedButton disabled={isDeactivation} onClick={handleClose}>No</OutlinedButton>
        </>
      )}
    </Modal>
  );
};
