import {
  attach,
  combine,
  createEffect,
  createEvent,
  createStore,
  guard,
  restore,
  sample,
} from "effector";

import { PagingParams } from "shared/api";

const defaultPaging = {
  data: [],
  hasMore: false,
  page: 1,
  pageSize: 30,
  searchText: "",
  count: 0,
};

export const createPageable = <T>(
  fetch: (params: Partial<PagingParams>) => Promise<Pageable<T>>
) => {
  const loadFirst = createEvent<void>();
  const loadNext = createEvent<void>();
  const loadPage = createEvent<number>();
  const loadPrev = createEvent<void>();
  const update = createEvent<void>();
  const changePageSize = createEvent<number>();
  const loadFx = createEffect(fetch);
  const $searchField = createStore("");
  const searchText = createEvent<string>();
  sample({
    clock: searchText,
    target: $searchField,
  });

  const $data = restore(loadFx.doneData, defaultPaging);
  sample({
    source: $data,
    clock: $searchField,
    fn: (data, searchText) => ({
      ...data, searchText
    }),
    target: $data
  });
  const $isEmpty = combine(
    $data,
    loadFx.pending,
    ({ data }, isPending) => !isPending && data.length === 0
  );

  const $isLoading = createStore(false);

  sample({
    clock: loadFx,
    fn: () => true,
    target: $isLoading,
  });

  sample({
    clock: loadFx.done,
    fn: () => false,
    target: $isLoading,
  });

  sample({
    clock: loadFirst,
    source: $data,
    fn: ({ pageSize }) => ({ page: 1, pageSize }),
    target: loadFx,
  });

  const loadNextFx = attach({
    effect: loadFx,
    source: combine({
      data: $data,
      search: $searchField,
    }),
    mapParams: (_, { data, search }) => {
      const { page, pageSize } = data;
      return {
        page: page + 1,
        pageSize,
        searchText: search
      };
    },
  });
  guard({
    clock: loadNext,
    source: $data,
    filter: ({ hasMore }) => hasMore,
    target: loadNextFx,
  });

  const loadPageFx = attach({
    effect: loadFx,
    source: combine({
      data: $data,
      search: $searchField,
    }),
    mapParams: (pageNumber: number, { data, search }) => {
      const { pageSize } = data;
      return {
        page: pageNumber,
        pageSize,
        searchText: search
      };
    },
  });
  sample({
    clock: loadPage,
    target: loadPageFx,
  });

const loadPrevFx = attach({
  effect: loadFx,
  source: combine({
    data: $data,
    search: $searchField,
  }),
  mapParams: (_, { data, search }) => {
    const { page, pageSize } = data;
    return {
      page: page - 1,
      pageSize,
      searchText: search
    };
  },
});

guard({
  clock: loadPrev,
  source: $data,
  filter: ({ page }) => page !== 1,
  target: loadPrevFx,
});

sample({
  source: $searchField,
  clock: changePageSize,
  fn: (search, pageSize) => ({ page: 1, pageSize, searchText: search}),
  target: loadFx,
});

sample({
  clock: update,
  source: combine({
    data: $data,
    search: $searchField,
  }),
  fn: ({ data, search }) => ({ page: data.page, pageSize: data.pageSize, searchText: search }),
  target: loadFx,
});

sample({
  clock: searchText,
  source: $data,
  fn: ({ pageSize, searchText }) => ({ page: 1, pageSize, searchText }),
  target: loadFx,
});

return {
  $data,
  $isEmpty,
  $isLoading,
  loadFirst,
  loadPage,
  loadNext,
  loadPrev,
  update,
  changePageSize,
  searchText
};
};
