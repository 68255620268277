import { forward, sample } from "effector";
import { createGate } from "effector-react";

import { setToken } from "shared/api/instance";

import { loadAccountFx } from "features/auth/model";

export const AppGate = createGate();

sample({
  clock: AppGate.open,
  fn:()=>{
    const accessToken = localStorage.getItem("ACCESS_TOKEN");
    !!accessToken && setToken(accessToken);
  }
}),

forward({
  from: AppGate.open,
  to: loadAccountFx,
});
